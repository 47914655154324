@import './vars';

@mixin input-wrapper {
    box-sizing: border-box;
    background: $white;
    border-radius: 8px;
    height: 100%;
    max-height: 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 0 0 0 15px;
    width: 100%;
    max-width: 660px;
    box-shadow: 0px 17px 116px rgba(0, 0, 0, 0.05), 0px 3.79717px 25.9101px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 7.71412px rgba(0, 0, 0, 0.0201946);
}

@mixin input {
    border: none;
    background-color: transparent;
    padding: 0 10px;
    box-sizing: border-box;
    color: $subTitleColor;
    width: calc(100% - 35px);
    height: 100%;
    min-height: 40px;

    &:focus {
        outline: none;
    }
}