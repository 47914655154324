@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadingAnim {
    width: 22px;
    height: 22px;
    animation: rotate 1s infinite;
}