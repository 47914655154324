@import 'src/styles/vars';

.homeHero {
    text-align: center;
    position: relative;
    height: 300px;
    background-color: #f6f6f6;
    margin-bottom: 50px;

    @media (min-width: $tablet + 1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: $tablet) {
        padding: 20px;

        .homeHeroContent {
            >h1 {
                top: 60px !important;
                font-size: 26px !important;
                max-width: 300px;
            }
        }
    }

    .homeHeroLogo {
        position: absolute;
        top: 20px;
    }

    .homeHeroContent {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 0;

        @media (max-width: $largeTablet) {
            .homeRightImg {
                display: none;
            }
        }

        @media (max-width: $tablet) {
            .homeLeftImg {
                bottom: -20px !important; // because of the padding of the parent
            }
        }

        @media (max-width: $mobile) {
            .homeLeftImg {
                display: none;
            }
        }

        .homeLeftImg {
            position: absolute;
            width: 308px;
            bottom: 0px;
            left: calc(19% - 200px);
            z-index: -1;
        }

        >h1 {
            position: absolute;
            top: 120px;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            color: $titleColor;

            >span {
                color: $primary;
            }
        }

        >h3 {
            position: absolute;
            top: 180px;
            font-size: 16px;
            color: $subTitleColor;
        }

        .searchFormWrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute !important;
            top: 230px !important;
        }

        .homeRightImg {
            position: absolute;
            width: 315px;
            bottom: 0px;
            right: calc(18% - 180px);
            z-index: -1;
        }
    }
}