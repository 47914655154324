@import 'src/styles/vars';
@import 'src/styles/input';

.searchAutocompleteWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 660px;

    .searchBar {
        @include input-wrapper;
        padding-left: 0px !important;
        padding-right: 15px !important;
        background-color: $pageBackgroundColor;
        margin: 0 auto;

        .searchBtnWrapper {
            height: 100%;
            width: 46px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 99px;
            border-bottom-right-radius: 99px;
            // margin-left: 10px;
            // cursor: pointer;
            transition: background-color 0.2s ease-in-out;
        }

        .searchInput {
            color: $titleColor !important;
            @include input;
        }

        .searchDelete {
            cursor: pointer;
            min-width: 18px;
            height: 100%;
            display: flex;
            align-items: center;

            >svg {
                fill: #676767;
            }
        }

        &.autoOpen {
            border-radius: 20px 20px 0px 0px;
        }
    }
}