@import 'src/styles/vars';

@mixin primary-btn($width: 200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    height: 100%;
    width: 100%;
    max-width: $width;
    text-decoration: none;
    box-shadow: 0px 17px 116px rgba(0, 0, 0, 0.05), 0px 3.79717px 25.9101px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 7.71412px rgba(0, 0, 0, 0.0201946);
    border-radius: 8px;
    color: $white;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: $primaryFont;
    transition: background-color 0.2s ease-in-out;
    padding: 0px 4px;
    white-space: pre;
    min-height: 35px;
    font-size: 14px;

    &:hover {
        background-color: $primaryHover;
    }
}

@mixin ctaButton {
    cursor: pointer;
    @media (min-width: $largeTablet + 1) {
        max-width: 320px;
        min-width: 200px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    @media (max-width: $largeTablet) {
        max-width: 320px;
        min-width: 200px;
        width: 100%;
    }

    .cta {
        border: 1px solid $primary;
        font-weight: bold;
        display: flex;
        text-decoration: none;
        justify-content: center;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 6px;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin-bottom: 0px !important;
        background-color: $primary;
        color: $white;
        max-height: 45px;

        &:hover {
            background-color: $primaryHover;
            color: white;
        }

        img {
            width: 100px;
            height: auto;
            object-fit: contain;
        }

        .icon-wrapper {
            margin-right: 5px;
        }
    }
}