@import 'src/styles/vars';
@import 'src/styles/grid';

.topCategories {
    .topCategoriesSection {
        .sectionTitle {
            color: $titleColor;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 30px;
        }

        .sectionContent {
            display: flex;
            flex-wrap: wrap;

            .topCat {
                @include grid(5, 5, 5, 3, 2);
                background-color: $white;
                box-shadow: 0px 17px 116px rgba(0, 0, 0, 0.05), 0px 3.79717px 25.9101px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 7.71412px rgba(0, 0, 0, 0.0201946);
                border-radius: 8px;
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: background-color 0.2s ease-in-out;
                text-decoration: none !important;

                &:hover {
                    background-color: rgba(0, 189, 157, 0.08);
                }

                .topCatName {
                    max-width: 150px;
                    text-align: center;
                    color: $titleColor;
                    font-size: 18px;
                    font-weight: normal;
                }
            }
        }
    }
}