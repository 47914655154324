@import 'src/styles/vars';
@import 'src/styles/input';
@import 'src/styles/bestreviews.zone/btn';

.wrapper {
    height: $newslettersHeight;
    background-color: #f6f6f6;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    @media (max-width: $tablet) {
        height: auto !important;
        padding: 20px;

        .newsletters {
            flex-direction: column;

            .imgWrapper {
                margin-bottom: 40px;
                margin-right: 0px !important;
                > img {
                    object-fit: contain;
                }
            }

            .ctaBox {
                height: 240px !important;

                .actionsWrapper {
                    flex-direction: column;

                    .inputWrapper {
                        margin-right: 0px !important;
                        margin-bottom: 10px;
                    }

                    >button {
                        height: 35px !important;
                    }
                }
            }
        }
    }

    .newsletters {
        display: flex;
        justify-content: center;
        align-items: center;

        .imgWrapper {
            margin-right: 50px;
            width: 250px;
            height: 180px;
            position: relative;
            >img {
                width: 250px;
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }

        .ctaBox {
            display: flex;
            flex-direction: column;
            height: 160px;
            justify-content: space-evenly;

            >h2 {
                font-weight: bold;
                font-size: 24px;
                color: $titleColor;
            }

            >h3 {
                font-size: 16px;
                color: $subTitleColor;
            }

            .error {
                font-size: 14px;
                color: $red;
                margin-top: 5px;
            }

            .actionsWrapper {
                display: flex;
                align-items: center;

                .inputWrapper {
                    @include input-wrapper;
                    margin-right: 20px;

                    >input {
                        @include input;
                    }
                }

                >button {
                    @include primary-btn;
                    transition: 0.2s ease-in-out;

                    &.sent {
                        pointer-events: none;
                        background-color: $green;
                    }

                }
            }
        }
    }
}