@import 'src/styles/vars';
@import 'src/styles/grid';
@import 'src/styles/text';
@import 'src/styles/bestreviews.zone/btn';

.item {
    @include grid(4, 4, 4, 3, 2);
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 17px 116px rgba(0, 0, 0, 0.05), 0px 3.79717px 25.9101px rgba(0, 0, 0, 0.0298054), 0px 1.13052px 7.71412px rgba(0, 0, 0, 0.0201946);
    border-radius: 8px;

    &.yItem {
        position: relative;
        padding: 20px 10px 10px 10px !important;

        .yPrice {
            position: absolute;
            font-size: 12px;
            color: #4444444d;
            top: 5px;
            left: 5px;
        }
    }

    .imgWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        max-height: 200px;

        .imgClickWrapper {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 200px;
            max-height: 200px;

            >img {
                max-width: 100%;
                max-height: 85%;
            }
        }
        >img {
            max-width: 85%;
            max-height: 85%;
        }

    }

    .titleWrapper {
        font-size: 15px;
        line-height: 130%;
        height: 390%;
        color: $subTitleColor;
        @include maxLines(3);

        .titleCTA {
            &:hover {
                text-decoration: underline;
            }
        }
        
        .blackTitle {
            color: $black;
        }
    }

    .ctaWrapper {
        display: flex;
        justify-content: center;
        margin: 20px auto 10px auto;
        width: 100%;
        max-width: 150px;

        .primaryBtn {
            @include primary-btn;
        }
    }

    &.compliantItem {
        position: relative;

        .co {
            position: absolute;
            top: 1px;
            font-size: 12px;
            z-index: 1;
            right: 3px;
            color: rgba(68, 68, 68, 0.5);
        }

        .ctaWrapper {
            color: $titleColor;
        }
    }
}