.container {
    position: relative;
}

.box {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    bottom: calc(100% + 5px);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
    line-height: 20px;
    font-size: 13px;
    min-width: 250px;
    left: -48px;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
}


.arrow {
    position: absolute;
    top: -10px;
    left: 50%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}