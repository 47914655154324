@use "sass:math";
@import 'src/styles/vars';

@function divider($dividend, $divisor) {
    // $result: $dividend / $divisor; // will be deprecated soon
    $result: calc($dividend / $divisor);
    @return $result;
    // @return math.div($dividend, $divisor); // will be in the future
}

@mixin marginColumn($columns, $gutter) {
    margin-right: divider(($gutter * $columns), ($columns - 1));

    &:nth-child(#{$columns}n) {
        margin-right: 0px;
    }
}

@mixin grid($veryLargeDesktopColumns: 5, $desktopColumns: 4, $laptopColumns: 3, $tabletColumns: 3, $mobileColumns: 2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(50% - 20px);
    max-height: 450px;
    margin-bottom: 10px;

    // mobileColumns < 544
    @media (max-width: $mobile) {
        $gutter: 3px;
        width: calc(#{(divider(100%, #{$mobileColumns}))} - #{$gutter});
        @include marginColumn($mobileColumns, $gutter);
    }

    // tabletColumns 545 - 1024
    @media (min-width: $mobile + 1) and (max-width: $largeTablet) {
        $gutter: 4px;
        width: calc(#{(divider(100%, #{$tabletColumns}))} - #{$gutter});
        @include marginColumn($tabletColumns, $gutter);
    }

    // laptopColumns 1025 - 1240
    @media (min-width: $largeTablet + 1) and (max-width: $largeDesktop) {
        $gutter: 6px;
        width: calc(#{(divider(100%, #{$laptopColumns}))} - #{$gutter});
        @include marginColumn($laptopColumns, $gutter);
    }

    // desktopColumns 1241 - 1500
    @media (min-width: $largeDesktop + 1) and (max-width: $veryLargeDesktop) {
        $gutter: 8px;
        width: calc(#{(divider(100%, #{$desktopColumns}))} - #{$gutter});
        @include marginColumn($desktopColumns, $gutter);
    }

    // veryLargeDesktopColumns > 1500
    @media (min-width: $veryLargeDesktop + 1) {
        $gutter: 8px;
        margin-bottom: 20px;
        width: calc(#{divider(100%, #{$veryLargeDesktopColumns})} - #{$gutter});
        @include marginColumn($veryLargeDesktopColumns, $gutter);
    }
}