$minimum-support: 345px;
$small-mobile: 374px;
$mobile: 544px;
$tablet: 768px;
$largeTablet: 1024px;
$laptop: 1140px;
$desktop: 1240px;
$largeDesktop: 1500px;
$largerDesktop: 1350px;
$veryLargeDesktop: 1501px;

$Rubik: 'Rubik',
sans-serif;
$Montserrat: 'Montserrat',
sans-serif;
$primaryFont: $Rubik;
$secondaryFont: $Rubik;
$subTitleColor: rgba(68, 68, 68, 0.5);
$titleColor: #444444;
$lighterPrimary: rgba(0, 189, 157, 0.2);

$orange: #f9b903;
$red: #e42024;
$green: #4BB543;
$lightSilver: #F5F5F5;
$silver: #eeeeee;
$darkSilver: #898989;
$black: #2e2e2e;
$white: #ffff;
$loadingBoxColor: #f1f1f1;
$mobileIconsColor: #6f6f6f;
$grayerBackground: #4444440d;

$primary: #00BD9D;
$primaryHover: #02aa8e;
$secondary: $white;
$header: $white;

$footer: $titleColor;
$footerText: $white;

$filterMobile: $white;
$filterMobileText: $black;
$filterButtonOutline: $white;
$filterButtonColor: $primary;

$defaultPagePadding: 55px;
$defaultMobilePagePadding: 20px;
$pageMaxWidth: $laptop;
$pageBackgroundColor: $silver;
$headerHeight: 60px;
$footerHeight: 380px;
$newslettersHeight: 300px;
$mobileNewslettersHeight: 500px;
$mobileHeaderHeight: 48px;
$minDesktopContentHeight: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$newslettersHeight});
$minMobileContentHeight: calc(100vh - #{$mobileHeaderHeight} - #{$footerHeight} - #{$mobileNewslettersHeight});
