@import 'src/styles/vars';


.footerWrapper {
    background: $footer;
    height: $footerHeight;

    .footer {
        max-width: $pageMaxWidth;
        margin: 0 auto;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        height: 100%;

        .credits {
            height: 150px;
            justify-content: space-between;
            display: flex;
            flex-direction: column;

            .disclaimer {
                color: $footerText;
                font-size: 14px;
                margin-bottom: 10px;
                cursor: pointer;
            }
            
            .description {
                margin: 12px 10px 12px 0;
                color: $footerText;
                font-size: 13px;
            }

            .operated {
                .siteName {
                    font-weight: bold;
                    font-size: 17.5872px;
                    color: $footerText;
                }

                .rights {
                    font-size: 15px;
                    font-weight: normal;
                    color: $footerText;
                }

            }

            .owned {
                color: $footerText;
                font-size: 11px;
            }
        }

        .links {
            display: flex;
            margin-left: auto;

            li {
                display: flex;
                align-items: center;

                a {
                    text-decoration: none;
                    color: $footerText;
                    font-size: 14px;
                    line-height: 1;
                    padding: 0 10px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}


@media (max-width: $laptop) {
    .footer {
        padding: 10px !important;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        height: $footerHeight;
        line-height: 2;

        .credits {
            align-items: center;
            line-height: 20px;

            .description {
                display: block;
                text-align: center;
                margin-bottom: 12px;
                margin-top: 12px;
            }

            .operated {
                .siteName {
                    display: block;
                    text-align: center;
                }

                .rights {
                    font-size: 12px;
                    display: block;
                    text-align: center;
                }

            }
        }

        .links {
            margin-left: 0 !important;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 20px;

            li {
                margin: 10px 0px;
                a {
                    font-size: 12px !important;
                }
            }
        }
    }
}