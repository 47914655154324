@import 'src/styles/vars';

.appLogo {
    height: 100%;
    min-height: 40px;
    display: flex;
    justify-content: flex-start;
    max-width: 140px;
    min-width: 100px;

    .logoImg {
        max-width: 140px !important;
        height: 100%;
        width: 100%;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url('/bestreviews.zone/logo.svg');
        background-position: center;
        min-height: 30px;
    }

    &.whiteLogo {
        height: 40px;
        .logoImg {
            background-image: url('/bestreviews.zone/logo-white.svg') !important;
        }
    }
}