@import 'src/styles/vars';

.wrapper {
    .section {
        .content {
            .category {
                margin: 100px 0px;
                .categoryLine {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 40px;

                    >span {
                        color: $titleColor;
                        font-size: 22px;
                        line-height: 22px;
                        font-weight: 600;

                        @media (max-width: $tablet) {
                            font-size: 18px !important;
                        }
                    }

                    >a {
                        color: $primary;
                        font-size: 15px;
                        border-bottom: 1px dashed $primary;
                        text-decoration: none;
                        cursor: pointer;
                        white-space: pre;

                        @media (max-width: $tablet) {
                            font-size: 14px !important;
                        }
                    }
                }

                .categoryItems {
                    .itemWrapper {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

            }
        }
    }
}