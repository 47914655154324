@import 'src/styles/vars';

@mixin item-padding {
    border-radius: 2px;
    padding: 8px 4px 8px 4px;
    max-width: calc(100% - 15px);
    margin: 0px 0px 4px 0px;
    justify-content: space-between;
    display: flex;
}


.autocompleteWrapper {
    position: absolute;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    z-index: 11;
    background-color: $white;
    border-radius: 4px;

    .autocomplete {
        padding-bottom: 10px;

        .searchOption {
            .option {
                @include item-padding;
                margin: 5px 20px 0px 24px !important;
                line-height: 22px;
                text-align: left;
                font-size: 14px;
                text-transform: capitalize;
                color: $titleColor;
                text-decoration: none;
                transition: background-color 0.1s ease-in-out;
                cursor: pointer;

                &:hover {
                    background-color: $silver;
                }
            }
        }

        .listsWrapper {
            >h4 {
                line-height: 22px;
                text-align: left;
                font-size: 16px;
                text-transform: capitalize;
                color: $subTitleColor;
                font-weight: 400;
                text-decoration: none;
                padding: 8px 10px 8px 24px;
            }

            .lists {
                margin-block-start: 0em;
                margin-block-end: 0em;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                padding-inline-start: 40px;

                >li {
                    .notfound {
                        @include item-padding;
                        line-height: 22px;
                        text-align: left;
                        font-size: 14px;
                        color: $titleColor;
                        text-decoration: none;
                        display: block !important;
                        transition: background-color 0.1s ease-in-out;
                    }

                    .result {
                        @include item-padding;
                        line-height: 22px;
                        text-align: left;
                        font-size: 14px;
                        text-transform: capitalize;
                        transition: background-color 0.1s ease-in-out;
                        color: $primary;
                        text-decoration: none;

                        &:hover {
                            background-color: $silver;
                        }
                    }
                }

                .loaderWrapper {
                    list-style: none;
                    margin-left: -18px;
                }
            }
        }

    }
}