@import 'src/styles/vars';
// @import 'global';

// .content {
//     max-width: $pageMaxWidth;
//     padding: 16px $defaultPagePadding;

//     @media (max-width: $laptop) {
//         padding: 16px $defaultMobilePagePadding;
//     }
// }

.homePage {
    >section {
        max-width: $pageMaxWidth;
        margin: 0 auto;

        @media (max-width: $laptop) {
            padding: 20px;
            
        }
    }
}